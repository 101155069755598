import React from "react";
import { graphql } from "gatsby";
import WhoWeAreTemplate from "src/templates/who-we-are";

export const query = graphql`
	query WhoWeAreQuery {
		page: sanityWhoWeAre(_id: { regex: "/(drafts.|)whoWeAre/" }) {
			content: _rawContent(resolveReferences: { maxDepth: 8 })
		}
	}
`;

const WhoWeArePage = (props: any) => {
	const { location } = props;
	const content = props.data.page.content;
	return <WhoWeAreTemplate location={location} data={content} />;
};

export default WhoWeArePage;
